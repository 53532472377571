<template>
    <div class="rc-nuestrosProductos" id="productos">
        <v-container class="rc-padding fluid">
            <v-layout row pb-5 wrap>
                <v-flex xs12 mt-5 style="text-align: center;">
                    <h1 class="rc-tituloSection" style="margin-bottom:2rem">Nuestros Productos</h1>
                    <!-- <p class="rc-subtituloSection">Enfoque RedCapital</p> -->
                    <v-layout row pb-5 wrap style="position:relative">
                        <div class="nuestrosProductos__titulo">
                            <div>
                                <h2>Factoring</h2>
                            </div>
                        </div>
                        <v-flex class="content__imgFactoring" xs12 md6 lg6>
                            <div class="nuestrosProductos__img factorging"></div>
                        </v-flex>
                        <v-flex class="content__textFactoring" xs12 md6 lg6>
                            <div class="nuestrosProductos__text text__right">
                                <h2 class="text__titulo__right">Factoring</h2>
                                <p>
                                    Recibe de manera anticipada el dinero de tus facturas. <br class="rc-responsive">
                                    En RedCapital solucionamos tu preocupación por falta <br  class="rc-responsive">
                                    de capital de trabajo
                                </p>
                                <ul>
                                    <li>Liquidez en poco tiempo.</li>
                                    <li>Optimiza tu flujo de caja.</li>
                                    <li>Financiamiento sin endeudamiento.</li>
                                    <li>Convierte tus ventas al crédito en ventas de contado.</li>
                                </ul>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row pb-5 wrap>
                        <v-flex class="content__textConfirming" xs12 md6 lg6>
                            <div class="nuestrosProductos__text text__right">
                                <h2 class="text__titulo__left">Confirming</h2>
                                <p>
                                    Gestiona, los pagos de tus proveedores y dales a estos <br class="rc-responsive">
                                    la opción de adelantar el cobro de sus facturas <br class="rc-responsive">
                                </p>
                                <ul>
                                    <li>Proceso 100% digital.</li>
                                    <li>
                                        Financiamiento de facturas por pagar, que te permite <br class="rc-responsive">
                                        adecuar el plazo de pago al flujo de tu negocio.
                                    </li>
                                </ul>
                            </div>
                        </v-flex>
                        <v-flex class="content__imgConfirming" xs12 md6 lg6>
                            <div class="nuestrosProductos__img confirming"></div>
                        </v-flex>
                    </v-layout>
                    <!-- <div class="nuestrosProductos__content">
                        <h2>Factoring</h2>
                        <div>
                            textos
                        </div>
                    </div> -->
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		/* props: {
			titulo : String,
			listado : Array
        }, */
	})
	export default class NuestrosProductos extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-nuestrosProductos{
        min-height: 800px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
       
       /*  .nuestrosProductos__content{
            //background-image: url('');
            background: url( '/images/homeView/Factoring.png') no-repeat right top;
        } */
        .nuestrosProductos__img{
            border-radius: 40px;
            &.factorging{
                height: 504px;
                width: 449px;
                background: url( '/images/homeView/Factoring.png');
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 40px;
            }
            &.confirming{
                height: 504px;
                width: 446px;
                background: url( '/images/homeView/Confirming.png');
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 40px;
            }
        }
        .nuestrosProductos__text{
            &.text__right{
                text-align: left;
            }
            .text__titulo__right{
                color: $rc-escala5;
                font-weight: 600;
                font-size: 2rem;
                background-color: $rc-prim-n2;
                border-radius: 10px;
                padding: 0px 40px 0px 40px;
                text-align: center;
                width: fit-content;
                margin-bottom: 2rem;
            }
            .text__titulo__left{
                color: $rc-prim-n2;
                font-weight: 600;
                font-size: 2rem;
                border-radius: 10px;
                padding: 0px 40px 0px 40px;
                text-align: center;
                width: fit-content;
                border: 1px solid $rc-prim-n2;
                margin-bottom: 2rem;
            }
            p{
                color: $rc-prim-g1;
                font-size: 1rem;
            }
            ul{
                color: $rc-prim-g2;
                font-size: 1rem;
            }
        }
        .nuestrosProductos__titulo{
            position: absolute; 
            width: 100%;
            div{
                position: relative;
                margin-top: 80px;
                display: flex;
                justify-content: center;
                h2{
                    color: $rc-escala5;
                    font-weight: 600;
                    font-size: 2rem;
                    background-color: $rc-prim-n2;
                    border-radius: 10px;
                    padding: 0px 40px 0px 40px;
                    text-align: center;
                    width: fit-content;

                }
            }
        }
        //
        .content__imgFactoring{
            display: flex;
            justify-content: flex-end;
        }
        .content__textFactoring{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .content__textConfirming{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .content__imgConfirming{
            display: flex;
            justify-content: flex-start;
        }
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
                .text__titulo__right{
                    display: none;
                }
			}@else if $breakpoint == "large"{
				.text__titulo__right{
                    display: none;
                }
			}@else if $breakpoint == "medium-to-large"{
                .rc-responsive {
                    display: block;
                }
				.text__titulo__right{
                    display: none;
                }
                .nuestrosProductos__img{
                    margin-top: 40px;
                    border-radius: 40px;
                    &.factorging{
                        height: 370px !important;
                        width: 330px !important;
                    }
                    &.confirming{
                        height: 370px !important;
                        width: 327px !important;
                    }
                }
                .nuestrosProductos__titulo{
                    div{
                        margin-top: 40px !important;
                    }
                }
            }@else if $breakpoint == "small-to-medium"{
                .rc-responsive {
                    display: contents;
                }
                .nuestrosProductos__titulo{
                    display: none;
                }
                .content__imgFactoring{
                    order: 2;
                    justify-content: center !important;
                }
                .content__textFactoring{
                    order: 1;
                }
                .content__textConfirming{
                    justify-content: flex-start !important;
                }
                .content__imgConfirming{
                    justify-content: center !important;
                }
                .nuestrosProductos__img{
                    margin-top: 40px;
                    border-radius: 40px;
                    &.factorging{
                        margin-right: 0px !important;
                        height: 392px !important;
                        width: 350px !important;
                    }
                    &.confirming{
                        margin-left: 0px !important;
                        height: 392px !important;
                        width: 347px !important;
                    }
                }
            }@else if $breakpoint == "small"{
                .rc-responsive {
                    display: contents;
                }
                .nuestrosProductos__titulo{
                    display: none;
                }
                .content__imgFactoring{
                    order: 2;
                    justify-content: center !important;
                }
                .content__textFactoring{
                    order: 1;
                }
                .content__textConfirming{
                    justify-content: flex-start !important;
                }
                .content__imgConfirming{
                    justify-content: center !important;
                }
                .nuestrosProductos__img{
                    margin-top: 40px;
                    border-radius: 40px;
                    &.factorging{
                        margin-right: 0px !important;
                        height: 334px !important;
                        width: 298px !important;
                    }
                    &.confirming{
                        margin-left: 0px !important;
                        height: 334px !important;
                        width: 296px !important;
                    }
                }
            }
		}
	}
</style>
