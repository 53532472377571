








































import { Component, Prop, Vue } from "vue-property-decorator";
import { Configuration } from "@/configuration";

@Component({
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    reducedValues() {
      return this.$props.value
        .map((bar: any) => bar.value)
        .reduce((last: number, current: number) => last + current, 0);
    }
  },
  methods: {
    minWidth(width: number) {
      return width < 5 ? "40px" : `${width <= 100 ? width : 100}%`;
    }
  }
})
export default class ProgressBar extends Vue {}
