






































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import {
  RedCapital,
  SelectedCountry,
  Configuration,
  TitleTemplatePublic
} from "@/configuration";
import GReCaptcha from "@/components/GReCaptcha.vue";
import API from "@/api";
import { EventBus } from "@/main";
import { isUserLogin } from "@/utils";

@Component({
  metaInfo: {
    title: "Contacto",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      valid: true,
      name: "",
      email: "",
      message: "",
      canPress: true,
      dialog: false,
      errorMessage: null,
      captcha: false,
      nameRules: [
        (v: any) => !!v || "Ingrese nombre",
        (v: any) =>
          v.length <= 255 || "Nombre no puede exceder los 255 caracteres."
      ],
      emailRules: [
        (v: any) => !!v || "Ingrese correo electrónico",
        (v: any) => /.+@.+/.test(v) || "La dirección debe ser válida"
      ],
      messageRules: [
        (v: any) => !!v || "Ingrese mensaje",
        (v: any) =>
          v.length >= 5 || "El mensaje debe tener al menos 5 caracteres."
      ],
      rulephone: [
        (v: any) => !!v || 'Teléfono es requerido',
        (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros",
        (v: any) => v.length > 7 || "Minimo 8 caracteres",
        (v: any) => !!v || "Ingrese un valor"
      ],
      phone:"",
      clasificacion:'',
      clasificacion_id: "",
      clasificaciones:[],
    };
  },
  beforeMount() {
    isUserLogin(() => {
      this.$data.name = `${this.$store.state.user.nombre} ${this.$store.state.user.app_pat}`;
      this.$data.email = this.$store.state.user.email;
    });
    API.listar_clasificaciones()
    .then((res: any)=>{
      this.$data.clasificaciones = res
    })
  },
  watch: {
    clasificacion(v){
        this.$data.clasificacion_id = v.id;
    },
  },
  methods: {
    async validate(response) {
      this.$data.canPress = false;
      // @ts-ignore
      if (this.$refs.form.validate()) {
         /* console.log(this.$data.name);
         console.log(this.$data.email);
         console.log(this.$data.phone);
         console.log(this.$data.message);
         console.log(this.$data.clasificacion_id); */
         API.contact(this.$data.name, this.$data.email, this.$data.phone, this.$data.message, this.$data.clasificacion_id)
          .then((data: any) => {
            this.$data.dialog = true;
            setTimeout(() => {
              this.$data.dialog = false;
             /*  this.$router.push({
                path: "/"
              }); */
              this.$router.go(0);
            }, Configuration.Redirection.timeout);
          })
          .catch(res => {
            this.$data.canPress = true;
            this.$data.errorMessage = "Error";
            if (res.message.nombre != null) {
              EventBus.$emit("snack-error", res.message.nombre[0]);
            }
            if (res.message.mail != null) {
              EventBus.$emit("snack-error", res.message.mail[0]);
            }
            if (res.message.mensaje != null) {
              EventBus.$emit("snack-error", res.message.mensaje[0]);
            }
          });
      }
    },
    doRecaptcha() {
      // @ts-ignore
      this.$refs.recaptchaContact.execute();
    }
  },
  components: {
    GReCaptcha
  }
})
export default class ContactPeru extends Vue {}
