<template>
    <div class="rc-pertenecemos">
        <v-container class="rc-padding fluid">
            <v-layout row pb-5 wrap>
                <v-flex xs12 mt-5 style="text-align: center;">
                    <h1 class="rc-tituloSection" style="margin-bottom:2rem">Organizaciones que pertenecemos</h1>
                </v-flex>
                <v-flex class="pertenecemos__content" xs12 mt-5>
                    <div class="content profin"></div>
                    <div class="content cavali"></div>
                    <div class="content superin"></div>
                    <!-- <img src="/images/homeView/pertenecemos/Logo_aprofin.png" height="120px" alt="Profin - Finanza para Mipymes">
                    <img src="/images/homeView/pertenecemos/Logo_cavali.png" height="120px" alt="Cavali">
                    <img src="/images/homeView/pertenecemos/Logo_superin.png" height="120px" alt="Superintendencia de banca"> -->
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		/* props: {
			titulo : String,
			listado : Array
        }, */
	})
	export default class Pertenecemos extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-pertenecemos{
        min-height: 800px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .pertenecemos__content{
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            .content{
                height: 120px;
                min-width: 400px;
                margin-top: 1rem;
                /* max-width: 400px; */
                &.profin{
                    background: url('/images/homeView/pertenecemos/Logo_aprofin.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.cavali{
                    background: url('/images/homeView/pertenecemos/Logo_cavali.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.superin{
                    background: url('/images/homeView/pertenecemos/Logo_superin.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

            }
            img{
                height: auto;
                min-width: 300px;
                max-width: 400px;
            }
        }

        
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
                
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
                
            }@else if $breakpoint == "small-to-medium"{
                .rc-pertenecemos{
                    .pertenecemos__content{
                        .content{
                            min-width: 400px;
                        }
                    }
                }
            }@else if $breakpoint == "small"{
                .rc-pertenecemos{
                    .pertenecemos__content{
                        .content{
                            min-width: 300px;
                        }
                    }
                }
            }
		}
	}
</style>
