





















































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { EventBus } from "@/main";
import { Backend, SelectedCountry, RedCapital } from "@/configuration";
import { Project, ProjectInterface } from "@/models/Project";
import ProgressBar from "@/components/ProgressBar.vue";
import ProgressBarCredit from "@/components/ProgressBarCredit.vue";
import API from "@/api";

@Component({
  computed: {
    imagen() {
      return this.$props.project.imagen.ruta.startsWith("/")
        ? `${Backend}${this.$props.project.imagen.ruta}`
        : this.$props.project.imagen.ruta;
      // return this.$props.project.imagen.ruta.startsWith('/') ? this.$props.project.imagen.ruta != '/storage/img/pic' ? `${Backend}${this.$props.project.image.ruta}` : false : this.$props.project.imagen.ruta
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry
    };
  },
  methods: {
    info() {
      EventBus.$emit("open-info-dialog");
    },
    getIcon(id) {
      switch (id) {
        case 1:
          return "description";
        case 2:
          return "drafts";
        case 3:
          return "markunread";
        case 4:
          return "featured_play_list";
      }
    },
    addProjectView(e) {
      API.addProjectView(this.$props.project.id).finally(() => {
        this.$router.push({
          path: `/simulacion/proyecto-${this.$props.project.id}`
        });
      });
    },
    addSecretView(e) {
      API.addProjectView(this.$props.project.id).finally(() => {
        this.$router.push({
          path: `/simulacion/proyecto-${this.$props.project.id}?s=t`
        });
      });
    }
    // projectName() {
    //   let max = 50
    //   if (this.$props.project.nombre.length >= max) {
    //     return `${this.$props.project.nombre.split('').slice(0, max).join('')}...`
    //   } else {
    //     return this.$props.project.nombre
    //   }
    // },
  },
  components: {
    ProgressBar,
    ProgressBarCredit
  }
})
export default class ProjectCard extends Vue {
  @Prop() project!: Project;
  @Prop({ default: false }) secret!: boolean;
}
