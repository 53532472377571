

















































































































































































import { Component, Vue } from "vue-property-decorator";
import {
	TitleTemplatePublic,
	RedCapital,
	SelectedCountry,
	RedCapitalConfig
} from "@/configuration";
import ProjectCard from "@/components/ProjectCardHome.vue";
import ContactPeru from "@/views/ContactPeru.vue";
import NumbersPanel from "@/components/homeView/NumbersPanel.vue";
import NuestrosProductos from "@/components/homeView/NuestrosProductos.vue";
import Pertenecemos from "@/components/homeView/Pertenecemos.vue";
import CardWeDo from "@/components/CardItem.vue";
import ISO from "@/components/ISO/ISO.vue";
import { mapState } from "vuex";
import { EventBus } from "@/main";
import API from "@/api";


@Component({
	metaInfo: {
		title: "RedCapital  | Plataforma de Financiamiento Digital ",
		//titleTemplate: TitleTemplatePublic,
		htmlAttrs: {
			// @ts-ignore
			lang: RedCapital[SelectedCountry].Lang
		}
	},
	computed: {
		...mapState(["user", "amountFinanced"]),
		projectsVisibility() {
			return (
				this.$store.state.user != null ||
				RedCapitalConfig(SelectedCountry).Project.PublicVisibility
			);
		}
	},
	data() {
		return {
			RedCapital,
			SelectedCountry,
			navigationNext: `<i class="material-icons primary--text">arrow_forward_ios</i>`,
			navigationPrevious: `<i class="material-icons primary--text">arrow_back_ios</i>`,
			whatDoWeDoInfo: [],
			projects: null,
			options: {
				projects: {
					perPage: 4
				},
				press: {
					perPage: 4
				}
			},
			amounts: {
				financed: 100,
				pymes: 1100,
				renta: 13.5,
				lastThirtyDays: 0,
				render: {
					financed: 100,
					pymes: 1100,
					renta: 13.5,
					lastThirtyDays: 0
				}
			},
			ejecutarAnimacion : false,
		};
	},
	watch: {
		user() {
			// @ts-ignore
			this.obtainProjects();
		}
	},
	async beforeMount() {
		// @ts-ignore
		this.obtainProjects();

		API.queHacemos().then((res) => {
			const response = res;
			this.$data.whatDoWeDoInfo = response;
		}).catch((err) => {
			console.log('err', err);
		})
	},
	async mounted() {
		setTimeout(()=>{
			this.$data.ejecutarAnimacion = true;
		},1000);
		
		// @ts-ignore
		this.onResponsive();
		// @ts-ignore
		window.addEventListener("resize", this.onResponsive);
		// @ts-ignore
		// setInterval(()=>{this.efectoTitulo()}, 6000);
	},
	beforeDestroy() {
		// @ts-ignore
		window.removeEventListener("resize", this.onResponsive);
	},
	methods: {
		onResponsive() {
			let w = window.innerWidth;
			if (w < 600) {
				this.$data.options.projects.perPage = 1;
				this.$data.options.press.perPage = 2;
			} else if (w > 600 && w < 960) {
				this.$data.options.projects.perPage = 2;
				this.$data.options.press.perPage = 3;
			} else if (w > 960 && w < 1264) {
				this.$data.options.projects.perPage = 3;
				this.$data.options.press.perPage = 4;
			} else if (w > 1264 && w < 1904) {
				this.$data.options.projects.perPage = 3;
				this.$data.options.press.perPage = 5;
			} else if (w > 1904) {
				this.$data.options.projects.perPage = 4;
				this.$data.options.press.perPage = 8;
			}
		},
		obtainProjects() {
			// @ts-ignore
			if (this.projectsVisibility) {
				API.getProjects({
					factura: 1,
					credito: 1,
					tasa: 3,
					cuota: 24,
					offset: 0
				}).then((projects: any) => {
					this.$data.projects =
					projects.length < 12
					? projects.slice(0, projects.length)
					: projects.slice(0, 12);
					
					let i:any = [], j:any = []
					
					this.$data.projects.forEach((element:any) => {
						element.native_porcentaje_invertido < 99 ?
						i.push(element)
						: j.push(element)
					})

					i.sort((a:any,b:any)=>{
						if(a.monto_disponible < b.monto_disponible){
							return 1
						}else{
							return -1
						}
					})

					this.$data.projects= i.concat(j)
				});
			}
		},
		fadeInText(el, texto, time) {
			el.innerHTML = texto;
			el.style.opacity = 0;
			var last = +new Date();
			var tick = function() {
				// @ts-ignore
				el.style.opacity = +el.style.opacity + (new Date() - last) / time;
				last = +new Date();
				if (+el.style.opacity < 1) {
				// @ts-ignore
				(window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
				}
			};
			tick();
		},
		// efectoTitulo(){
		// 	// @ts-ignore
		// 	if(document.getElementById('rc-cambioTexto').innerHTML == 'Invertir'){
		// 		// @ts-ignore
		// 		this.fadeInText(document.getElementById('rc-cambioTexto'), 'Financiarte', 3000);
		// 	}else{
		// 		// @ts-ignore
		// 		this.fadeInText(document.getElementById('rc-cambioTexto'), 'Invertir', 3000);
		// 	}
		// }
	},
	components: {
		ProjectCard,
		ContactPeru,
		CardWeDo,
		NumbersPanel,
		NuestrosProductos,
		Pertenecemos,
		ISO
	}
})
export default class HomeNew extends Vue{}
