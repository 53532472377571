












import { Component, Vue } from "vue-property-decorator";
@Component({
    props:{
        item: {
            type: Object,
            default: () => ({
                image: String,
                title: String,
                descripcion: String,
            })
        }
    }, 
})
export default class CardItem extends Vue{}
